import React from 'react';
import { BsTwitter, BsInstagram} from 'react-icons/bs';
import { FaFacebookF, FaYoutube, FaGithub } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
  <div className='social__Icons'>
    <a href="" target="_blank">
      <FaFacebookF />
    </a>
  </div>
    <div className='social__Icons'>
      <a href="" target="_blank">
        <FaYoutube />
      </a>
    </div>
    
  </div>
);

export default SocialMedia;
