import logostari from "./logo.svg";
import logo from "./Logo.png";

import "./App.css";
import Navbar from "./Navbar/Navbar";
import Header from "./Header/Header";
import Taxi from "./components/Taxi/Taxi";
import ScrollToTop from "react-scroll-to-top";
import Airport from "./components/Airport/Airport";

//import { AppWrap, MotionWrap } from "./wrapper";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Taxi />
      <Airport />
      <ScrollToTop smooth />
    </div>
  );
}

export default App;
