import React from 'react'
import NumberCounter from "number-counter"
import {motion} from "framer-motion";
import './Header.scss';
import { images } from '../constants';
import { AppWrap } from '../wrapper';
import { FaPhone,FaWhatsapp } from 'react-icons/fa';


const handleWhatsappClick = () => {
  window.open('https://api.whatsapp.com/send?phone=+385989949997', '_blank');
};


const transition = {type:"spring", duration:3}








const Header = () => {
  return (
    <div className='app__header'>
      {/*BROJAĆI------------------- */}
     <motion.div 
        className='brojaci'
        whileInView={{ x: [-170, 0], opacity: [0, 1] }}
        transition={{ duration: 1 }}>
     <div>
       <span>
       <NumberCounter end={244} start={150} delay="4" preFix="+"/>
       </span>
       <span className="p-text">airport</span>
       <span className="p-text">transfer</span>
     </div>
     <div>
       <span >
       <NumberCounter end={91} start={30} delay="4" preFix="%"/>

       </span>
       <span>customer</span>
       <span className="p-text">return</span>

     </div>
     <div>
       <span>
       <NumberCounter end={22} start={0} delay="4" preFix="+"/>

       </span>
       <span>vehicles</span>
       <span className="p-text"></span>

     </div>
   </motion.div>

    {/* LOGO */}
   <img src={images.LogoTransparent} className="LogoTaxi" alt="logo" />
   <motion.div
   whileInView={{ opacity: 1 }}
   whileHover={{ scale: 1.5 }}
   transition={{ duration: 0.5, type: 'tween' }}
   className="pozivi"
 >
   <a href="tel:+385989949997" className="phone_number">
     <FaWhatsapp
       className="whatsapp_icon_big"
       onClick={handleWhatsappClick}
     />
   </a>
 </motion.div>



{/*the best.....------------ */}
<div className='letsStart'>
  <motion.div
  initial={{left:"250px"}}
  whileInView={{left:"8px"}}
  transition={{...transition, type:"tween"}}
  ></motion.div>    
  <span>Let's start with a WhatsUp message</span>
</div>



 

    {/* I N F O */}
    <div className='info'>
      <div className='info__block'>
        <img src={images.transport} className="" alt="transport" />Airport
        <p className='p-text'>Looking for Premium Airport Transfers in Split?"
        <br />
        Book now for a limited-time offer of just €50!
       </p>
      </div>
      <div className='info__block'>
        <img src={images.marina} className="" alt="transport" />Marina
        <p className='p-text'>Book now and enjoy a seamless and luxurious journey to or from any marina with our Premium Van transfers. 
        <br />
        Contact us today to get started!</p>
      </div>
      <div className='info__block'>
        <img src={images.city} className="" alt="transport" />City
        <p className='p-text'>Book now and enjoy a seamless and luxurious transfer within or between any cities in Croatia with our premium services. 
        <br />
        Whether you need transportation from the airport to your hotel, a transfer between cities, or a convenient ride within the city, we have you covered.</p>
      </div>
 
    </div>
    {/* OUR SERVICES */}
    <div className='info'>
      <div className='info__block'>
        <img src={images.privateTransfer} className="" alt="transport" />Private Transfers
        <p className='p-text'>Your own private vehicle with a friendly and knowledgeable driver. Travel in style with Executive + VIP Transfers, featuring 5-star vehicles and a warm welcome.</p>
      </div>

      <div className='info__block'>
        <img src={images.city} className="" alt="transport" />Shared / Shuttle Transfers
        <p className='p-text'> Enjoy our cost-effective transportation option that ensures a swift, convenient, and seamless door-to-door experience.</p>
      </div>
      <div className='info__block'>
        <img src={images.excursion} className="" alt="transport" />Excursion
        <p className='p-text'> Immerse yourself in the sights and sounds of your chosen excursion with our reliable and comfortable transfer service. Enjoy a hassle-free journey from your location to the excursion destination and make the most of your adventure.</p>
      </div>    
    </div>


    
    
    
    </div>
  )
}

export default AppWrap(Header, 'home')