import React, { useState, useEffect } from 'react';
import { createClient } from 'contentful';
import { motion } from 'framer-motion';
import "./Airport.scss"
import { FaPhone,FaWhatsapp } from 'react-icons/fa';
import { AppWrap } from '../../wrapper';
import { images } from '../../constants';




//Contentful data
const client = createClient({
  space: '1mcsqzjp5p0c',
  accessToken: 'BEzt-IDZ8TgMyoHvZ2KmyvgvD31m7kiVJmQwUQ87riM',
});

const Airport = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'taxi',
        });
        setData(response.items);
      } catch (error) {
        console.error('Error fetching data from Contentful:', error);
      }
    };

    fetchData();
  }, []);




//funkcija za direktni poziv prko webv stranice
const handlePhoneClick = (phoneNumber) => {
  window.location.href = `tel:${phoneNumber}`;
};


//funkcija za Whats Up poruka preko stramice
const handleWhatsappClick = (phoneNumber) => {
  const mobilePhoneNumber = phoneNumber.replace(/\D/g, '');
  const whatsappUrl = `https://wa.me/${mobilePhoneNumber}`;
  window.open(whatsappUrl, '_blank');
};





  return (
    <div className="app__taxi">
    <img src={images.award01} className="number_one_image" alt="logo" />
    
    <p className="p-text_airport" >Contact us today 
    <br />
    to secure your airport transfer 
    <br />
    and discover 
    <br />
    why we are the number one choice in Split 
    <br />
    and its surroundings.</p>
    <h1 className='stroke-text'>AIRPORT</h1>

      {data ? (
        <ul className="Taxi__cards">
          {data.map((item) => (
            <motion.div 

              key={item.sys.id}
              className='Taxi_card'>

              <img className='taxi_card_image' src={item.fields.typeOfVehicle.fields.file.url} alt={item.fields.typeOfVehicle.fields.title} />


              <div>           
              <p key={item.sys.id} className="bold-text">
                {item.fields.licensePlate}
              </p>            
              
              <p key={item.sys.id} className="p-text">
              {item.fields.vrstaVozila}
              </p> 

              <p key={item.sys.id} className="p-text">
              Max PAX: {item.fields.enableLocalizationOfThisFieldMaximumNumberO}
              </p> 
              
              </div>
              
              <p key={item.sys.id} className="p-text">
              {item.fields.ime}
              </p>
              



                <motion.div 
                whileInView={{ opacity: 1 }}
                whileHover={{ scale: 1.5 }}
                transition={{ duration: 0.5, type: 'tween' }}
                key={item.sys.id} className="aaa">
                  <FaPhone
                    className="phone_icon"
                    onClick={() => handlePhoneClick(item.fields.contactNumber2)}/>
                </motion.div>

                <motion.div 
                whileInView={{ opacity: 1 }}
                whileHover={{ scale: 1.5 }}
                transition={{ duration: 0.5, type: 'tween' }}
                key={item.sys.id} className="aaa">
                  <FaWhatsapp
                  className="whatsapp_icon"
                  onClick={() => handleWhatsappClick(item.fields.whatsUpNumber)}/>
                </motion.div>
                
            
            
            </motion.div>
          ))}
        </ul>
      ) : (
        <p>Loading data...</p>
      )}
    </div>
  );
};

export default AppWrap(Airport, 'airport')


    
    
    
    
    
    
    
    
    
    
