import award01 from "../assets/award01.png";
import logo192 from "../assets/logo192.png";
import Logo from "../assets/Logo.png";
import LogoTransparent from "../assets/LogoTransparent.png";
import van from "../assets/van.png";
import taxi4youLogo from "../assets/taxi4youLogo.png";
import taxi4youLogoIco from "../assets/taxi4youLogo.ico";
import phone from "../assets/phone.png";
import transport from "../assets/Icons/transport.png";
import marina from "../assets/Icons/marina.png";
import city from "../assets/Icons/city.png";
import excursion from "../assets/Icons/excursion.png";
import privateTransfer from "../assets/Icons/privateTransfer.png";

export default {
  award01,
  logo192,
  Logo,
  van,
  taxi4youLogoIco,
  taxi4youLogo,
  LogoTransparent,
  phone,
  transport,
  marina,
  city,
  excursion,
  privateTransfer,
};
